//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      show: false,
      key: '',
      index: -1
    }
  },
  methods: {
    todoFromBook() {
      this.$emit('selectTodoMsg', {
        type: 'book',
        data: {
          key: this.key,
          index: this.index
        }
      })
      this.show = false
    },
    toSelectLabel() {
      this.$emit('selectTodoMsg', {
        type: 'label',
        data: {
          key: this.key,
          index: this.index
        }
      })
      this.show = false
    },
    open({ key, index }) {
      this.show = true
      this.key = key
      this.index = index
    }
  }
}
